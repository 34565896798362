import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom'
import Slip from './components/page2/Slip';

const Home = lazy(() => import('./components/Home'))
const Main = lazy(() => import('./components/Main'))

function App() {
  return (
    <Routes>
      <Route>
        <Route path='/' element={<Home />} />
        <Route path='/pelanggan' element={<Main />} />
        <Route path='/slip' element={<Slip />} />
      </Route>
    </Routes>
  );
}

export default App;
